import { Controller } from 'stimulus';
import $ from 'jquery';

const COMPANY_STATUS_MIGRATING = 'Migrating';
const COMPANY_STATUS_MIGRATED = 'Migrated';
const COMPANY_STATUS_INACTIVE = 'Inactive';

const READINESS_STATUS_QUEUED = 'Queued';
const READINESS_STATUS_STALE = 'Stale';
const READINESS_STATUS_PENDING = 'Pending';
const READINESS_STATUS_NOT_READY = 'NotReady';
const READINESS_STATUS_READY = 'Ready';

const REPORT_STATUS_GENERATING = 'Generating';
const REPORT_STATUS_COMPLETE = 'Complete';
const REPORT_STATUS_FAILED = 'Failed';

const TEMPLATE_READINESS_IS_READY = 'readinessIsReadyTemplate';
const TEMPLATE_READINESS_WARNING = 'readinessWarningsTemplate';
const TEMPLATE_READINESS_ERRORS = 'readinessErrorsTemplate';
const TEMPLATE_NEXT_STEP_TEXT = 'nextStepTextTemplate';
const TEMPLATE_NEXT_STEP_PROGRESS = 'nextStepProgressTemplate';
const TEMPLATE_NEXT_STEP_REFRESH = 'nextStepRefreshTemplate';
const TEMPLATE_NEXT_STEP_MIGRATE = 'nextStepMigrateTemplate';
const TEMPLATE_NEXT_STEP_REPORT = 'nextStepReportTemplate';
const TEMPLATE_NEXT_STEP_VIEW_IN_EN = 'nextStepViewInENTemplate';
const TEMPLATE_POST_MIGRATION_REPORT_GENERATING = 'postMigrationReportGeneratingTemplate';
const TEMPLATE_POST_MIGRATION_REPORT_FAILED = 'postMigrationReportFailedTemplate';
const TEMPLATE_POST_MIGRATION_REPORT_BUTTON = 'postMigrationReportButtonTemplate';
const TEMPLATE_RESULT_MIGRATION_FAILED_BUTTON = 'resultMigrationFailedTemplate';
const TEMPLATE_DEACTIVATE_BUTTON = 'deactivateButtonTemplate';
const TEMPLATE_DEACTIVATE_AND_ALREADY_MIGRATED_LINK = 'deactivateAndAlreadyMigratedLinkTemplate';
const TEMPLATE_PROGRESS_LOGO = 'progressLogoTemplate';

const CONTAINER_STATUS = '#companyStatus';
const CONTAINER_NEXT_STEP = '#nextStepContainer';
const CONTAINER_READINESS = '#readinessContainer';
const CONTAINER_POST_MIGRATION_REPORT = '#postMigrationReportContainer';
const CONTAINER_OTHER = '#otherContainer';

export default class extends Controller {
  initialize() {
    this.brokerId = this.data.get('broker-id');
    this.actionName = this.data.get('refresh-action');
    this.xsrf = $('input[type="hidden"][name="_xsrf_"]').val(); // Get the XSRF from any form on the page
    const comapnyIdsJson = this.data.get('company-ids');
    this.companyIds = `{${JSON.parse(comapnyIdsJson).join(', ')}}`; // This is the format for list expected by the ease backend
    this.discard = false; // Used to determine if a polling result should be discarded
  }

  connect() {
    this.element['migrationDashboardController'] = this; // Allow access to the controller via the attached element
    this.isPolling = false;
    this.interval = setInterval(() => this.poll(), 5000);
  }

  disconnect() {
    clearInterval(this.interval);
  }

  async poll() {
    if (this.isPolling) return; // Prevent overlapping executions
    this.isPolling = true;

    const formData = new FormData();
    formData.append('_formid_', this.actionName);
    formData.append('_xsrf_', this.xsrf);
    formData.append('brokerId', this.brokerId);
    formData.append('companyIds', this.companyIds);

    try {
      const response = await fetch(window.location.href, {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        const data = await response.json();
        this.handleResponse(data);
      } else {
        console.error('Polling failed:', response.statusText);
      }
    } catch (error) {
      console.error('Polling error:', error);
    } finally {
      this.isPolling = false;
    }
  }

  handleResponse(data) {
    if (this.discard === true) {
      console.log('Discarding events');
      this.discard = false;
      return;
    }
    const { events } = data.jsonData;

    // Update a company row for each event
    if (events !== undefined && events.length > 0) {
      events.forEach(x => this.updateCompanyRow(x));
    }
  }

  discardResponse() {
    this.discard = true;
  }

  updateCompanyRow(event) {
    const companyRow = $(`[row-company-id="${event.CompanyId}"]`);
    if (companyRow.length === 0) {
      // Event is for a company not displayed
      return;
    }

    const status = companyRow.find(CONTAINER_STATUS);
    status.text(event.CompanyStatus);

    switch (event.CompanyStatus) {
      case COMPANY_STATUS_MIGRATING:
        this.updateForMigrating(companyRow, event);
        break;
      case COMPANY_STATUS_MIGRATED:
        this.updateForMigrated(companyRow, event);
        break;
      case COMPANY_STATUS_INACTIVE:
        this.updateForInactive(companyRow, event);
        break;
      default:
        this.updateForActive(companyRow, event);
    }
  }

  updateForInactive(companyRow, event) {
    const nextStepContainer = companyRow.find(CONTAINER_NEXT_STEP);
    const readinessContainer = companyRow.find(CONTAINER_READINESS);
    const postReportContainer = companyRow.find(CONTAINER_POST_MIGRATION_REPORT);
    const otherContainer = companyRow.find(CONTAINER_OTHER);

    // Result col
    this.updatePostReport(event, postReportContainer);

    nextStepContainer.empty();
    readinessContainer.empty();
    otherContainer.empty();
  }

  updateForMigrating(companyRow, event) {
    const nextStepContainer = companyRow.find(CONTAINER_NEXT_STEP);
    const readinessContainer = companyRow.find(CONTAINER_READINESS);
    const postReportContainer = companyRow.find(CONTAINER_POST_MIGRATION_REPORT);
    const otherContainer = companyRow.find(CONTAINER_OTHER);

    // Readiness col
    readinessContainer.empty();

    // Other col
    otherContainer.empty();

    // Next step col (show progress status text)
    const text = event.MigrationProgressText ?? '';
    let nextStepTemplate = document.getElementById(TEMPLATE_NEXT_STEP_PROGRESS).innerHTML;
    nextStepTemplate = nextStepTemplate.replace('{{migrationStep}}', text);
    nextStepContainer.html(nextStepTemplate);

    // Result col
    if (postReportContainer.find('.logo-container').length === 0) {
      // Add the logo container if not already added
      // Otherwise do not add to allow animation of progress change
      const progressTemplate = document.getElementById(TEMPLATE_PROGRESS_LOGO).innerHTML;
      postReportContainer.html(progressTemplate);
    }

    // Update the progress

    const progress = Math.min(100, Math.max(0, event.MigrationProgressPercent));

    const logoFillOuter = postReportContainer.find('.logo-fill-outer');
    const logoFillInner = postReportContainer.find('.logo-fill-inner');

    // Fill outer circle first (0-80%), then inner star (80-100%)
    if (progress <= 80) {
      const outerProgress = (progress / 80) * 100;
      logoFillOuter.css('--progress', `${outerProgress}%`);
      logoFillInner.css('clip-path', 'inset(100% 0 0 0)');
    } else {
      logoFillOuter.css('--progress', '100%');
      const innerClipValue = 100 - ((progress - 80) / 20) * 100;
      logoFillInner.css('clip-path', `inset(${innerClipValue}% 0 0 0)`);
    }
  }

  updateForMigrated(companyRow, event) {
    const nextStepContainer = companyRow.find(CONTAINER_NEXT_STEP);
    const readinessContainer = companyRow.find(CONTAINER_READINESS);
    const postReportContainer = companyRow.find(CONTAINER_POST_MIGRATION_REPORT);
    const otherContainer = companyRow.find(CONTAINER_OTHER);

    // Readiness col
    readinessContainer.empty();

    // Next step col
    if (event.MigrationSuccess) {
      let template = document.getElementById(TEMPLATE_NEXT_STEP_VIEW_IN_EN).innerHTML;
      template = template.replace('{{enCompanyUrl}}', event.ViewInEnUrl);
      nextStepContainer.html(template);
    } else {
      nextStepContainer.empty();
    }

    // Result col
    this.updatePostReport(event, postReportContainer);

    // Other col
    let deactivateTemplate = document.getElementById(TEMPLATE_DEACTIVATE_BUTTON).innerHTML;
    deactivateTemplate = deactivateTemplate.replace('{{companyId}}', event.CompanyId);
    otherContainer.html(deactivateTemplate);
  }

  updateForActive(companyRow, event) {
    const nextStepContainer = companyRow.find(CONTAINER_NEXT_STEP);
    const readinessContainer = companyRow.find(CONTAINER_READINESS);
    const postReportContainer = companyRow.find(CONTAINER_POST_MIGRATION_REPORT);
    const otherContainer = companyRow.find(CONTAINER_OTHER);

    // Result col
    if (event.MigrationFailed) {
      let template = document.getElementById(TEMPLATE_RESULT_MIGRATION_FAILED_BUTTON).innerHTML;
      template = template.replace('{{failedText}}', event.MigrationFailedText);
      postReportContainer.html(template);
    } else {
      postReportContainer.empty();
    }

    // Other col
    let template = document.getElementById(TEMPLATE_DEACTIVATE_AND_ALREADY_MIGRATED_LINK).innerHTML;
    template = template.replace(/{{companyId}}/g, event.CompanyId); // Contains multiple companyId tokens
    otherContainer.html(template);

    // Readiness & Next Step cols
    switch (event.ReadinessStatus) {
      case READINESS_STATUS_STALE:
        template = document.getElementById(TEMPLATE_NEXT_STEP_REFRESH).innerHTML;
        template = template.replace('{{companyId}}', event.CompanyId);
        nextStepContainer.html(template);
        readinessContainer.empty();
        break;

      case READINESS_STATUS_QUEUED:
        template = document.getElementById(TEMPLATE_NEXT_STEP_TEXT).innerHTML;
        template = template.replace('{{nextStepText}}', 'Refresh');
        nextStepContainer.html(template);
        readinessContainer.empty();
        break;

      case READINESS_STATUS_PENDING:
        template = document.getElementById(TEMPLATE_NEXT_STEP_TEXT).innerHTML;
        template = template.replace('{{nextStepText}}', 'Refresh');
        readinessContainer.empty();
        break;

      case READINESS_STATUS_NOT_READY:
        template = document.getElementById(TEMPLATE_NEXT_STEP_REPORT).innerHTML;
        template = template.replace('{{companyId}}', event.CompanyId);
        template = template.replace('{{reportUrl}}', event.ReadinessReportUrl);
        nextStepContainer.html(template);

        template = document.getElementById(TEMPLATE_READINESS_ERRORS).innerHTML;
        template = template.replace('{{warningsText}}', this.createWarningsText(event));
        template = template.replace('{{errorsText}}', this.createErrorsText(event));
        readinessContainer.html(template);

        break;

      case READINESS_STATUS_READY:
        template = document.getElementById(TEMPLATE_NEXT_STEP_MIGRATE).innerHTML;
        template = template.replace(/{{companyId}}/g, event.CompanyId); // Contains multiple companyId tokens
        nextStepContainer.html(template);

        if (event.ReadinessWarnings > 0) {
          if (readinessContainer.find('.readiness-warning-container').length === 0) {
            // Only add the warning container if not already added
            // Prevents disrupting tooltip when updating UI
            template = document.getElementById(TEMPLATE_READINESS_WARNING).innerHTML;
            template = template.replace('{{warningsText}}', this.createWarningsText(event));
            template = template.replace('{{reportUrl}}', event.ReadinessReportUrl);
            readinessContainer.html(template);
          }
        } else {
          template = document.getElementById(TEMPLATE_READINESS_IS_READY).innerHTML;
          readinessContainer.html(template);
        }

        break;

      default:
    }
  }

  createWarningsText(event) {
    return event.ReadinessWarnings ? `${event.ReadinessWarnings} warning(s)` : '';
  }

  createErrorsText(event) {
    let errors = '<div>';
    if (event.ReadinessCompanyErrors > 0) errors += 'company configuration <br>';
    if (event.ReadinessUnsupportedPlans > 0)
      errors += `${event.ReadinessUnsupportedPlans} plans<br>`;
    if (event.ReadinessUnsupportedEmployees > 0)
      errors += `${event.ReadinessUnsupportedEmployees} employees<br>`;
    if (event.ReadinessUnsupportedEnrollments > 0)
      errors += `${event.ReadinessUnsupportedEnrollments} enrollments<br>`;
    if (event.ReadinessUnsupportedManageChanges > 0)
      errors += `${event.ReadinessUnsupportedManageChanges} unresolved todos (enrollments)<br>`;
    if (event.ReadinessUnsupportedPartners > 0)
      errors += `${event.ReadinessUnsupportedPartners} unsupported partners`;
    errors += '</div>';
    return errors;
  }

  updatePostReport(event, postReportContainer) {
    let template = null;
    switch (event.PostMigrationReportStatus) {
      case REPORT_STATUS_GENERATING:
        template = document.getElementById(TEMPLATE_POST_MIGRATION_REPORT_GENERATING).innerHTML;
        postReportContainer.html(template);
        break;
      case REPORT_STATUS_COMPLETE:
        template = document.getElementById(TEMPLATE_POST_MIGRATION_REPORT_BUTTON).innerHTML;
        template = template.replace('{{reportUrl}}', event.PostMigrationReportUrl);
        postReportContainer.html(template);
        break;
      case REPORT_STATUS_FAILED:
        template = document.getElementById(TEMPLATE_POST_MIGRATION_REPORT_FAILED).innerHTML;
        postReportContainer.html(template);
        break;
      default:
        postReportContainer.empty();
        break;
    }
  }
}
